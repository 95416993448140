import ivclass from '@invideoio/web-shared/Modules/IVClass';
import useIntersectionObserver from '@react-hook/intersection-observer';
import React, { useContext, useEffect, useState } from 'react';

import IVButton from '@invideoio/web-shared/components/Button';
import { RedirectContext, StateContext } from '@store/store';
import { SideTextType } from '../../types';
import { OSType } from '@invideoio/web-shared/constants/common';
import { sendRudderStackAnalyticEvent } from '@utils/analytics';
import {
  AnalyticsProductType,
  ClickCtaType,
  ClickCtaUxSource,
} from 'src/types/analytics.types';
import { BaseAppType } from '@constants/common';

interface DetailsProps {
  sideText: SideTextType;
  buttonText: string;
  CTAUrl: string;
  subText: string;
  onCTAIntersection?: Function;
  os: string;
  platform: string;
}

const Details = (props: DetailsProps) => {
  const { sideText, buttonText, CTAUrl, subText, onCTAIntersection, os } =
    props;
  const { handleClick } = useContext(RedirectContext);
  const { baseAppType } = useContext(StateContext);

  const isIOS = os === OSType.iOS;
  const isAndroid = os === OSType.Android;
  const isMobile = isIOS || isAndroid;

  const url = 'https://invideo-ai.app.link/hp-hero-cta';

  const onClick = () => {
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.Top,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    }).finally(() => {
      handleClick(CTAUrl, {
        ux_source: ClickCtaUxSource.Top,
      });
    });
  };

  const [ctaRef, setCtaRef] = useState<any>(null);
  const [textRef, setTextRef] = useState<any>(null);

  function useIsIntersecting(sectionRef: any, threshold?: number) {
    const { isIntersecting } = useIntersectionObserver(sectionRef, {
      threshold: threshold,
    });
    return isIntersecting;
  }

  const isCtaIntersecting = useIsIntersecting(ctaRef, 1);
  const isTextIntersecting = useIsIntersecting(textRef, 0);

  useEffect(() => {
    if (onCTAIntersection) {
      onCTAIntersection(isTextIntersecting ? true : isCtaIntersecting);
    }
  }, [isCtaIntersecting, isTextIntersecting, onCTAIntersection]);

  return (
    <React.Fragment>
      <div
        className={ivclass(
          'iv-col-span-12 md:iv-col-span-8 sm:iv-col-span-4',
          'iv-col-start-3 iv-col-end-11 md:iv-col-start-2 md:iv-col-end-8 sm:iv-col-start-1',
          'iv-pt-50 md:iv-pt-60 sm:iv-pt-40',
        )}
      >
        <div
          className={ivclass(
            'xl:iv-text-heading-3xs lg:iv-text-heading-4xs md:iv-text-heading-4xs sm:iv-text-body-2xl',
            'xl:iv-m-heading-3xs lg:iv-m-heading-4xs md:iv-m-heading-4xs sm:iv-m-body-2xl',
            'iv-font-semibold iv-text-center',
            'xl:iv-px-5',
          )}
        >
          <span
            className={ivclass('iv-text-black-85', 'md:iv-inline sm:iv-inline')}
          >
            {sideText.highlightedText}
          </span>
          <span
            className={ivclass(
              'iv-text-black-50',
              'lg:iv-block md:iv-inline sm:iv-inline',
            )}
          >
            {sideText.plainText1}
          </span>
          {sideText.plainText2 && (
            <span
              className={ivclass(
                'iv-text-black-50',
                'iv-block md:iv-inline sm:iv-inline',
              )}
            >
              {sideText.plainText2}
            </span>
          )}
        </div>
      </div>

      <div
        className={ivclass(
          'iv-col-span-12 md:iv-col-span-8 sm:iv-col-span-4',
          'iv-mt-50 md:iv-mt-60 sm:iv-mt-40',
          'iv-text-center',
        )}
      >
        {isMobile && (
          <IVButton
            ref={setCtaRef}
            className={ivclass(
              'iv-flex',
              'iv-justify-center',
              'iv-items-center',
              'iv-cursor-pointer',
              'iv-h-65 sm:iv-h-52',
              'iv-rounded-full',
              'iv-bg-blue hover:iv-bg-blue-strong iv-transition',
              'iv-px-[24px] iv-py-20 sm:iv-px-20',
              'iv-text-grey-0',
              'iv-text-body-3xl sm:iv-text-body-2xl',
              'iv-font-bold sm:iv-font-semibold',
              'iv-mx-auto',
            )}
            type="button"
            onClick={() => {
              window.open(url, '_blank');
            }}
          >
            {isAndroid ? 'Get Android app' : 'Get iOS app'}
          </IVButton>
        )}
        <IVButton
          className={ivclass(
            'iv-mt-10',
            'iv-flex',
            'iv-justify-center',
            'iv-items-center',
            'iv-cursor-pointer',
            'iv-h-65 sm:iv-h-52',
            'iv-rounded-full iv-transition',
            {
              'iv-border-solid iv-border-2 iv-border-blue': isMobile,
              'iv-bg-transparent hover:iv-bg-blue iv-text-blue': isMobile,
              'iv-bg-blue hover:iv-bg-blue-strong iv-text-grey-0': !isMobile,
            },
            'iv-px-[24px] iv-py-20 sm:iv-px-20',
            'hover:iv-text-grey-0',
            'iv-text-body-3xl sm:iv-text-body-2xl',
            'iv-font-bold sm:iv-font-semibold',
            'iv-mx-auto',
          )}
          type="button"
          onClick={onClick}
        >
          {isMobile ? 'Try on web' : 'Try invideo AI'}
        </IVButton>

        <div
          className={ivclass(
            'iv-mt-[16px] sm:iv-mt-10 md:iv-pb-0 sm:iv-pb-0',
            'md:iv-mx-auto sm:iv-mx-auto',
            'iv-text-grey-50',
            'iv-text-body-m sm:iv-text-body-xs',
            'iv-font-medium',
          )}
        >
          {subText}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Details;
